import { AuthSession, AuthUser, confirmSignIn, fetchAuthSession, getCurrentUser, signIn, signOut } from "aws-amplify/auth";
import axios from "axios";
import { EndpointConfigs } from "../api/endpointConfigs";
import { useEffect, useState } from "react";
import { Amplify } from "aws-amplify";
export interface CognitoConfig {
  region: string;
  userPoolId: string;
  userPoolWebClientId: string;
}

export interface IAttribute {
  Name: string;
  Value: string;
}

export const useCognito = () => {
  const [cognitoConfig, setCognitoConfig] = useState<CognitoConfig | null>(null);
  const [loading, setLoading] = useState(true);
  const [authenticated, setAuthenticated] = useState(false);

  const fetchCognitoConfig = async () => {
    try {
      const response = await axios.get(EndpointConfigs.authnApiUrl());
      console.log("Retreived cognito config");
      const config: CognitoConfig = response.data;
      setCognitoConfig(config);
      Amplify.configure({
        Auth: {
          Cognito: {
            userPoolClientId: config.userPoolWebClientId,
            userPoolId: config.userPoolId,
            loginWith: {
              username: true,
            },
          },
        },
      });
      const session = await fetchAuthSession();
      if (session.tokens !== undefined) {
        // console.log(session.tokens.accessToken.toString());
        setAuthenticated(true);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching Cognito config:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchCognitoUser = async () => {
    const user = await getCurrentUser();
    return user;
  };

  useEffect(() => {
    fetchCognitoConfig();
  }, []);

  const authenticate = async (username: string, password: string) => {
    const { isSignedIn, nextStep } = await signIn({
      username,
      password,
    });
    setAuthenticated(isSignedIn);
  };

  return { cognitoConfig, loading, authenticated, authenticate, fetchCognitoUser };
};

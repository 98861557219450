import * as React from "react";

import SpaceBetween from "@cloudscape-design/components/space-between";
import Header from "@cloudscape-design/components/header";

import { CaseContext, QueryRecord } from "../common/types/models";
import ContentLayout from "@cloudscape-design/components/content-layout";
import Link from "@cloudscape-design/components/link";
import Flashbar, { FlashbarProps } from "@cloudscape-design/components/flashbar";
import { CaseContextTable } from "../component/table/CaseContextTable";
import { listCaseContexts, listQueryRecords } from "../api/caseContext";
import { CreateCaseWizard } from "../component/wizard/CreateCaseWizard";
import { CaseOverview } from "../component/preview/CaseOverview";
import { useEffect, useState } from "react";
import { QueryRecordView } from "../component/preview/QueryRecordView";

export const CaseView = () => {
  const [caseContextsLoading, setCaseContextsLoading] = useState(false);
  const [showCreateCaseWizard, setShowCreateCaseWizard] = useState(false);
  const [currentCaseContext, setCurrentCaseContext] = useState<CaseContext | null>(null);

  const [queryRecords, setQueryRecords] = useState<QueryRecord[]>([]);
  const [selectedSourceId, setSelectedSourceId] = useState<string | null>(null);
  const [selectedQueryRecord, setSelectedQueryRecord] = useState<QueryRecord | null>(null);
  useEffect(() => {
    if (currentCaseContext?.contextId) {
      listQueryRecords(currentCaseContext.contextId).then((records) => {
        setQueryRecords(records);
      });
    }
  }, [currentCaseContext]);

  useEffect(() => {
    if (selectedSourceId) {
      setSelectedQueryRecord(queryRecords.find((record) => record.sourceId === selectedSourceId) || null);
    } else {
      setSelectedQueryRecord(null);
    }
  }, [selectedSourceId, queryRecords]);

  const [notifications, setNotifications] = React.useState<FlashbarProps.MessageDefinition[]>([
    {
      type: "warning",
      content: "Page under construction",
      dismissible: false,
      id: "under_construction",
    },
  ]);
  const [caseContexts, setCaseContexts] = React.useState<CaseContext[]>([]);

  const refreshCaseContexts = async () => {
    setCaseContextsLoading(true);
    const caseContexts = await listCaseContexts("demo-user");
    setCaseContexts(caseContexts);
    setCaseContextsLoading(false);
  };

  React.useEffect(() => {
    refreshCaseContexts();
  }, []);

  return (
    <ContentLayout
      defaultPadding
      headerVariant="high-contrast"
      notifications={<Flashbar items={notifications} />}
      header={
        <Header variant="h1" info={<Link variant="info">Info</Link>}>
          Cases
        </Header>
      }
    >
      <SpaceBetween size="xl">
        <CaseContextTable
          items={caseContexts}
          loading={caseContextsLoading}
          onRefresh={refreshCaseContexts}
          onItemClicked={(item) => {
            setCurrentCaseContext(item);
            setSelectedSourceId(null);
            console.log(item);
          }}
        />
        <CaseOverview
          caseContext={currentCaseContext}
          onSourceClicked={(sourceId) => {
            setSelectedSourceId(sourceId);
          }}
        />
        <QueryRecordView record={selectedQueryRecord} />
        <CreateCaseWizard
          onSubmit={() => {
            refreshCaseContexts();
          }}
          onCancel={() => {}}
        />
      </SpaceBetween>
    </ContentLayout>
  );
};

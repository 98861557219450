import axios from "axios";
import { EndpointConfigs } from "./endpointConfigs";
import { CaseContext } from "../common/types/models";
import { CreateCaseContextRequest, UploadSourceRequest, UploadSourceToCaseContextRequest } from "../common/types/requests";

const CASE_CONTEXT_API_URL = EndpointConfigs.caseContextApiUrl();

export const listCaseContexts = async (userId: string): Promise<CaseContext[]> => {
  const response = await axios.post(
    `${CASE_CONTEXT_API_URL}/v1`,
    {
      userId,
    },
    {
      headers: {
        "Content-Type": "application/json",
        "X-Flux-Target": "ListCaseContexts",
      },
    }
  );
  if (response.status !== 200) {
    console.error("Failed to list case contexts");
    return [];
  }
  return response.data.results as CaseContext[];
};

export const createCaseContext = async (request: CreateCaseContextRequest): Promise<CaseContext> => {
  const response = await axios.post(`${CASE_CONTEXT_API_URL}/v1`, request, {
    headers: {
      "Content-Type": "application/json",
      "X-Flux-Target": "CreateCaseContext",
    },
  });
  if (response.status !== 200) {
    console.error("Failed to create case context");
    return {} as CaseContext;
  }
  return response.data.caseContext as CaseContext;
};

export const deleteCaseContext = async (userId: string, contextId: string) => {
  const response = await axios.post(
    `${CASE_CONTEXT_API_URL}/v1`,
    {
      userId,
      contextId,
    },
    {
      headers: {
        "Content-Type": "application/json",
        "X-Flux-Target": "DeleteCaseContext",
      },
    }
  );
  if (response.status !== 200) {
    console.error("Failed to delete case context");
  }
};

export const uploadSourceToCaseContext = async (request: UploadSourceToCaseContextRequest) => {
  const response = await axios.post(`${CASE_CONTEXT_API_URL}/v1`, request.content, {
    headers: {
      "Content-Type": "text/plain",
      "X-Flux-Target": "UploadSourceToCaseContext",
      "X-Flux-User-Id": request.userId,
      "X-Flux-Context-Id": request.contextId,
      "X-Flux-Source-Type": request.sourceType,
      "X-Flux-Source-Format": request.sourceFormat,
    },
  });
  if (response.status !== 200) {
    throw new Error("Failed to upload file");
  }
  return response.data.s3Path as string;
};

export const createKnowledgeFromCaseContext = async (userId: string, contextId: string, source: String) => {
  const response = await axios.post(
    `${CASE_CONTEXT_API_URL}/v0`,
    {
      userId,
      contextId,
      sourceS3Paths: [source],
    },
    {
      headers: {
        "Content-Type": "application/json",
        "X-Flux-Target": "CreateKnowledgeFromCaseContext",
      },
    }
  );
  if (response.status !== 200) {
    throw new Error("Failed to create knowledge from case context");
  }
  return response.data;
};

export const listQueryRecords = async (contextId: string) => {
  const response = await axios.post(
    `${CASE_CONTEXT_API_URL}/v1`,
    {
      contextId,
    },
    {
      headers: {
        "Content-Type": "application/json",
        "X-Flux-Target": "ListQueryRecords",
      },
    }
  );
  if (response.status !== 200) {
    throw new Error("Failed to list query records");
  }
  return response.data.records;
};

import { Button, CodeEditor, Container, ContentLayout, Form, FormField, Header, Input, Link, SpaceBetween } from "@cloudscape-design/components";
import { ConfigurationTable } from "../component/table/ConfigurationTable";
import { useEffect, useState } from "react";
import "ace-builds/css/ace.css";
import "ace-builds/css/theme/cloud_editor.css";
import "ace-builds/css/theme/cloud_editor_dark.css";
import { updateConfiguration, listConfigurations, createConfiguration } from "../api/savedReports";
import { Configuration } from "../common/types/models";

const DEMO_USER_ID = "demo-user";

export const ConfigurationView = () => {
  const [editorContent, setEditorContent] = useState("{}");
  const [preferences, setPreferences] = useState({});
  const [editorLoading, setEditorLoading] = useState(true);
  const [dataLoading, setDataLoading] = useState(true);
  const [updating, setUpdating] = useState(false);
  const [creating, setCreating] = useState(false);
  const [ace, setAce] = useState();
  const [name, setName] = useState("");
  const [configurations, setConfigurations] = useState<Configuration[]>([]);

  const [configurationId, setConfigurationId] = useState("");

  const i18nStrings = {
    loadingState: "Loading code editor",
    errorState: "There was an error loading the code editor.",
    errorStateRecovery: "Retry",

    editorGroupAriaLabel: "Code editor",
    statusBarGroupAriaLabel: "Status bar",

    cursorPosition: (row: any, column: any) => `Ln ${row}, Col ${column}`,
    errorsTab: "Errors",
    warningsTab: "Warnings",
    preferencesButtonAriaLabel: "Preferences",

    paneCloseButtonAriaLabel: "Close",

    preferencesModalHeader: "Preferences",
    preferencesModalCancel: "Cancel",
    preferencesModalConfirm: "Confirm",
    preferencesModalWrapLines: "Wrap lines",
    preferencesModalTheme: "Theme",
    preferencesModalLightThemes: "Light themes",
    preferencesModalDarkThemes: "Dark themes",
  };

  const handleRefresh = async () => {
    setDataLoading(true);
    const configurations = await listConfigurations(DEMO_USER_ID);
    setConfigurations(configurations);
    setDataLoading(false);
  };

  useEffect(() => {
    async function loadAce() {
      const ace = await import("ace-builds");
      await import("ace-builds/webpack-resolver");
      ace.config.set("useStrictCSP", true);
      return ace;
    }
    Promise.all([loadAce()])
      .then(([ace]) => setAce(ace as any))
      .finally(() => {
        setEditorLoading(false);
      });
  }, []);

  useEffect(() => {
    handleRefresh();
  }, []);

  return (
    <ContentLayout
      defaultPadding
      headerVariant="high-contrast"
      header={
        <Header variant="h1" info={<Link variant="info">Info</Link>}>
          Configuration
        </Header>
      }
    >
      <SpaceBetween size="xl">
        <ConfigurationTable
          items={configurations}
          loading={dataLoading}
          onRefresh={handleRefresh}
          onItemClicked={(item) => {
            setConfigurationId(item.configurationId);
            setName(item.name);
            setEditorContent(item.content);
          }}
        />
        <Container header={<Header description>Configuration Editor</Header>}>
          <Form
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Button
                  variant="normal"
                  onClick={() => {
                    setUpdating(true);
                    updateConfiguration(DEMO_USER_ID, configurationId, name, editorContent)
                      .catch((error) => {
                        console.error("Failed to update configuration:", error);
                      })
                      .finally(() => {
                        setUpdating(false);
                        handleRefresh();
                      });
                  }}
                  loading={updating}
                  disabled={configurationId.trim() === "" || name.trim() === ""}
                >
                  Update
                </Button>
                <Button
                  variant="primary"
                  onClick={() => {
                    setCreating(true);
                    createConfiguration(DEMO_USER_ID, name, editorContent)
                      .then((configurationId) => {
                        setConfigurationId(configurationId);
                      })
                      .catch((error) => {
                        console.error("Failed to create configuration:", error);
                      })
                      .finally(() => {
                        setCreating(false);
                        handleRefresh();
                      });
                  }}
                  loading={creating}
                  disabled={name.trim() === "" || configurationId.trim() !== ""}
                >
                  Create
                </Button>
              </SpaceBetween>
            }
          >
            <SpaceBetween size="l">
              <FormField label="Configuration ID">
                <Input value={configurationId} onChange={(event) => setConfigurationId(event.detail.value)} />
              </FormField>
              <FormField label="Name">
                <Input value={name} onChange={(event) => setName(event.detail.value)} />
              </FormField>
              <FormField label="Content">
                <CodeEditor
                  ace={ace}
                  value={editorContent}
                  language="json"
                  onDelayedChange={(event) => setEditorContent(event.detail.value)}
                  preferences={preferences}
                  onPreferencesChange={(event) => setPreferences(event.detail)}
                  loading={editorLoading}
                  i18nStrings={i18nStrings}
                  // should match the imports on top of this file
                  themes={{ light: ["cloud_editor"], dark: ["cloud_editor_dark"] }}
                />
              </FormField>
            </SpaceBetween>
          </Form>
        </Container>
      </SpaceBetween>
    </ContentLayout>
  );
};

const localDevStageOverride = "alpha";

export class EndpointConfigs {
  static featureExtractionApiUrl() {
    if (window.location.hostname === "localhost") {
      return `https://${localDevStageOverride}.feature-extraction.flux-systems.com`;
    } else if (window.location.hostname.includes("alpha")) {
      return "https://alpha.feature-extraction.flux-systems.com";
    }
    return "https://beta.feature-extraction.flux-systems.com";
  }

  static featureExtractionApiUrlV0() {
    if (window.location.hostname === "localhost") {
      return `https://${localDevStageOverride}-v0.feature-extraction.flux-systems.com`;
    } else if (window.location.hostname.includes("alpha")) {
      return `https://alpha-v0.feature-extraction.flux-systems.com`;
    }
    return `https://beta-v0.feature-extraction.flux-systems.com`;
  }

  static savedReportsApiUrl() {
    if (window.location.hostname === "localhost") {
      return `https://${localDevStageOverride}.saved-report.flux-systems.com`;
    } else if (window.location.hostname.includes("alpha")) {
      return "https://alpha.saved-report.flux-systems.com";
    }
    return "https://beta.saved-report.flux-systems.com";
  }

  static reportGenerationApiUrl() {
    if (window.location.hostname === "localhost") {
      return `https://${localDevStageOverride}.report-generation-engine.flux-systems.com`;
    } else if (window.location.hostname.includes("alpha")) {
      return "https://alpha.report-generation-engine.flux-systems.com";
    }
    return "https://beta.report-generation-engine.flux-systems.com";
  }

  static unifiedWorkflowApiUrl() {
    if (window.location.hostname === "localhost") {
      return `https://${localDevStageOverride}.unified-workflow.flux-systems.com`;
    } else if (window.location.hostname.includes("alpha")) {
      return "https://alpha.unified-workflow.flux-systems.com";
    }
    return "https://beta.unified-workflow.flux-systems.com";
  }

  static caseContextApiUrl() {
    if (window.location.hostname === "localhost") {
      return `https://${localDevStageOverride}.case-context.flux-systems.com`;
    } else if (window.location.hostname.includes("alpha")) {
      return "https://alpha.case-context.flux-systems.com";
    }
    return "https://beta.case-context.flux-systems.com";
  }

  static authnApiUrl() {
    if (window.location.hostname === "localhost") {
      return `https://${localDevStageOverride}.auth.flux-systems.com/authn/v0`;
    } else if (window.location.hostname.includes("alpha")) {
      return "https://alpha.auth.flux-systems.com/authn/v0";
    }
    return "https://beta.auth.flux-systems.com/authn/v0";
  }
}

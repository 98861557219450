import { FunctionComponent, useEffect, useState } from "react";
import { FluxWidgetProps } from "../../common/standards";
import { CaseContext } from "../../common/types/models";
import { Box, Button, Container, CopyToClipboard, Header, Link, ProgressBar, SpaceBetween, StatusIndicator, Table, Textarea } from "@cloudscape-design/components";
import KeyValuePairs from "@cloudscape-design/components/key-value-pairs";

export interface CaseOverviewProps extends FluxWidgetProps {
  caseContext: CaseContext | null;
  onSourceClicked: (sourceId: string) => void;
}

export interface SourceDocument {
  fullPath: string;
  type: string;
  id: string;
}

export const CaseOverview: FunctionComponent<CaseOverviewProps> = ({ caseContext, onSourceClicked }) => {
  const [selectedSourceDocuments, setSelectedSourceDocuments] = useState<SourceDocument[]>([]);

  if (!caseContext) return null;

  const renderSourceDocuments = (sourceS3Keys: string[]) => {
    if (!sourceS3Keys) return [];
    return sourceS3Keys
      .filter((s3Key) => !s3Key.endsWith(".metadata.json"))
      .map((s3Key) => {
        const parts = s3Key.split("/");
        return {
          fullPath: s3Key,
          type: parts[3],
          id: parts[4].split(".")[0],
        };
      });
  };

  return (
    <Container header={<Header variant="h2">Case Overview</Header>}>
      <SpaceBetween size="l">
        <KeyValuePairs
          columns={3}
          items={[
            {
              label: "Context ID",
              value: (
                <CopyToClipboard
                  copyButtonAriaLabel="Copy Context ID"
                  copyErrorText="Context ID failed to copy"
                  copySuccessText="Context ID copied"
                  textToCopy={caseContext.contextId}
                  variant="inline"
                />
              ),
            },
            {
              label: "Name",
              value: caseContext.name,
            },
            {
              label: "Status",
              value: <StatusIndicator>{caseContext.status}</StatusIndicator>,
            },
            {
              label: "Description",
              value: caseContext.description,
            },
            {
              label: "Updated At",
              value: caseContext.lastUpdatedAt,
            },
            {
              label: "Knowledge Updated At",
              value: caseContext.lastKnowledgeUpdatedAt,
            },
          ]}
        />
        <Table
          renderAriaLive={({ firstIndex, lastIndex, totalItemsCount }) => `Displaying items ${firstIndex} to ${lastIndex} of ${totalItemsCount}`}
          columnDefinitions={[
            {
              id: "id",
              header: "Source ID",
              cell: (item) => <Link onClick={() => onSourceClicked(item.id)}>{item.id || "-"}</Link>,
              // TODO: download source document on click
              sortingField: "id",
              isRowHeader: true,
            },
            {
              id: "type",
              header: "Source Type",
              cell: (item) => item.type || "-",
              sortingField: "type",
            },
          ]}
          enableKeyboardNavigation
          items={renderSourceDocuments(caseContext.sourceS3Keys)}
          loadingText="Loading resources"
          sortingDisabled
          variant="embedded"
          selectionType="multi"
          trackBy="id"
          selectedItems={selectedSourceDocuments}
          onSelectionChange={(e) => {
            setSelectedSourceDocuments(e.detail.selectedItems);
          }}
          empty={
            <Box margin={{ vertical: "xs" }} textAlign="center" color="inherit">
              <SpaceBetween size="m">
                <b>No resources</b>
                <Button>Create resource</Button>
              </SpaceBetween>
            </Box>
          }
          header={
            <Header
              variant="h3"
              actions={
                <SpaceBetween size="xs" direction="horizontal">
                  <Button> Add </Button>
                  <Button variant="primary"> Query </Button>
                </SpaceBetween>
              }
            >
              Documents
            </Header>
          }
        />
      </SpaceBetween>
    </Container>
  );
};

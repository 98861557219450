import { Button, Container, KeyValuePairs, SpaceBetween } from "@cloudscape-design/components";
import ContentLayout from "@cloudscape-design/components/content-layout";
import Header from "@cloudscape-design/components/header";
import Link from "@cloudscape-design/components/link";
import { useCognito } from "../auth/cognito";
import { useEffect, useState } from "react";
import { AuthUser, fetchAuthSession, signOut } from "aws-amplify/auth";
import { ConsoleRouteConfig } from "../common/routes";

export const ProfileView = () => {
  const { loading, authenticated, fetchCognitoUser } = useCognito();
  const [authUser, setAuthUser] = useState<AuthUser | null>(null);

  useEffect(() => {
    if (loading) return;
    if (!authenticated) {
      window.location.href = `#${ConsoleRouteConfig.auth.href}`;
    } else {
      fetchCognitoUser().then((user) => {
        setAuthUser(user);
      });
    }
  }, [loading, authenticated]);

  return (
    <ContentLayout
      defaultPadding
      headerVariant="high-contrast"
      header={
        <Header variant="h1" info={<Link variant="info">Info</Link>}>
          Profile
        </Header>
      }
    >
      <Container
        header={
          <Header
            variant="h2"
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Button
                  variant="normal"
                  onClick={() => {
                    signOut().then(() => {
                      window.location.href = `#${ConsoleRouteConfig.auth.href}`;
                    });
                  }}
                >
                  Sign Out
                </Button>
                <Button variant="primary">Edit</Button>
              </SpaceBetween>
            }
          >
            General
          </Header>
        }
      >
        <KeyValuePairs
          columns={2}
          items={[
            { label: "Username", value: authUser?.username },
            { label: "Password", value: "*******" },
          ]}
        />
      </Container>
    </ContentLayout>
  );
};

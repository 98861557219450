import { BreadcrumbGroup } from "@cloudscape-design/components";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ConsoleRouteConfig } from "../common/routes";

export const FluxBreadcrumbs = () => {
  const [items, setItems] = useState([
    {
      text: ConsoleRouteConfig.home.pageName,
      href: `#${ConsoleRouteConfig.home.href}`,
    },
  ]);

  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/") {
      setItems([
        {
          text: ConsoleRouteConfig.home.pageName,
          href: `#${ConsoleRouteConfig.home.href}`,
        },
      ]);
    } else if (location.pathname === ConsoleRouteConfig.demo.href) {
      setItems([
        {
          text: ConsoleRouteConfig.home.pageName,
          href: `#${ConsoleRouteConfig.home.href}`,
        },
        {
          text: ConsoleRouteConfig.demo.pageName,
          href: `#${ConsoleRouteConfig.demo.href}`,
        },
      ]);
    } else if (location.pathname === ConsoleRouteConfig.configuration.href) {
      setItems([
        {
          text: ConsoleRouteConfig.home.pageName,
          href: `#${ConsoleRouteConfig.home.href}`,
        },
        {
          text: ConsoleRouteConfig.configuration.pageName,
          href: `#${ConsoleRouteConfig.configuration.href}`,
        },
      ]);
    } else if (location.pathname === ConsoleRouteConfig.cases.href) {
      setItems([
        {
          text: ConsoleRouteConfig.home.pageName,
          href: `#${ConsoleRouteConfig.home.href}`,
        },
        {
          text: ConsoleRouteConfig.cases.pageName,
          href: `#${ConsoleRouteConfig.cases.href}`,
        },
      ]);
    } else if (location.pathname === ConsoleRouteConfig.auth.href) {
      setItems([
        {
          text: ConsoleRouteConfig.home.pageName,
          href: `#${ConsoleRouteConfig.home.href}`,
        },
        {
          text: ConsoleRouteConfig.auth.pageName,
          href: `#${ConsoleRouteConfig.auth.href}`,
        },
      ]);
    } else if (location.pathname === ConsoleRouteConfig.profile.href) {
      setItems([
        {
          text: ConsoleRouteConfig.home.pageName,
          href: `#${ConsoleRouteConfig.home.href}`,
        },
        {
          text: ConsoleRouteConfig.profile.pageName,
          href: `#${ConsoleRouteConfig.profile.href}`,
        },
      ]);
    }
    console.log("location", location.pathname);
  }, [location]);

  return <BreadcrumbGroup items={items} />;
};

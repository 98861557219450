import { FunctionComponent, useState } from "react";
import { FluxTableWidgetProps } from "../../common/standards";
import { CaseContext } from "../../common/types/models";
import Table from "@cloudscape-design/components/table";
import Link from "@cloudscape-design/components/link";
import Header from "@cloudscape-design/components/header";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Button from "@cloudscape-design/components/button";
import Box from "@cloudscape-design/components/box";
import { convertISOTimestampToLocaleString } from "../../common/helpers";
import { deleteCaseContext } from "../../api/caseContext";

export const CaseContextTable: FunctionComponent<FluxTableWidgetProps<CaseContext>> = ({ items, loading, onRefresh, onItemClicked }) => {
  const [selectedItems, setSelectedItems] = useState<CaseContext[]>([]);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);

  return (
    <Table
      header={
        <Header
          variant="h2"
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                iconName="refresh"
                loading={isRefreshing}
                onClick={() => {
                  setIsRefreshing(true);
                  onRefresh().finally(() => {
                    setIsRefreshing(false);
                  });
                }}
              />

              <Button
                disabled={selectedItems.length === 0}
                onClick={() => {
                  setIsDeleting(true);
                  Promise.all(
                    selectedItems.map((item) => {
                      return deleteCaseContext("demo-user", item.contextId);
                    })
                  )
                    .catch((error) => {
                      console.error("Failed to delete case contexts", error);
                    })
                    .finally(() => {
                      setIsDeleting(false);
                      setSelectedItems([]);
                      onRefresh();
                    });
                }}
                loading={isDeleting}
              >
                Delete
              </Button>
            </SpaceBetween>
          }
        >
          Case Contexts
        </Header>
      }
      loading={loading}
      empty={
        <Box margin={{ vertical: "xs" }} textAlign="center" color="inherit">
          <SpaceBetween size="m">
            <b>No resources</b>
            <Button>Create resource</Button>
          </SpaceBetween>
        </Box>
      }
      selectedItems={selectedItems}
      items={items}
      loadingText="Loading case contexts..."
      selectionType="multi"
      trackBy="contextId"
      onSelectionChange={(e) => {
        setSelectedItems(e.detail.selectedItems);
      }}
      columnDefinitions={[
        {
          id: "contextId",
          header: "Context ID",
          cell: (item) => item.contextId,
        },
        {
          id: "name",
          header: "Name",
          cell: (item) => (
            <Link
              onClick={() => {
                onItemClicked(item);
              }}
            >
              {item.name}
            </Link>
          ),
        },
        {
          id: "description",
          header: "Description",
          cell: (item) => item.description,
        },
        {
          id: "status",
          header: "Status",
          cell: (item) => item.status,
        },
        {
          id: "lastUpdatedAt",
          header: "Updated At",
          cell: (item) => convertISOTimestampToLocaleString(item.lastUpdatedAt),
        },
      ]}
    />
  );
};

import ContentLayout from "@cloudscape-design/components/content-layout";
import { useCognito } from "../auth/cognito";
import Flashbar, { FlashbarProps } from "@cloudscape-design/components/flashbar";
import { useEffect, useState } from "react";
import Header from "@cloudscape-design/components/header";
import Link from "@cloudscape-design/components/link";
import { ConsoleRouteConfig } from "../common/routes";
import FormField from "@cloudscape-design/components/form-field";
import Input from "@cloudscape-design/components/input";
import Form from "@cloudscape-design/components/form";
import Container from "@cloudscape-design/components/container";
import { Button, SpaceBetween } from "@cloudscape-design/components";
import { fetchAuthSession } from "aws-amplify/auth";

export const AuthView = () => {
  const { loading, authenticated, authenticate } = useCognito();
  const [notifications, setNotifications] = useState<FlashbarProps.MessageDefinition[]>([
    {
      type: "warning",
      content: "Page under construction",
      dismissible: false,
      id: "under_construction",
    },
  ]);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    fetchAuthSession().then((session) => {
      if (session.tokens !== undefined) {
        window.location.href = ConsoleRouteConfig.home.href;
      }
    });
  }, [loading, authenticated]);

  return (
    <ContentLayout
      defaultPadding
      headerVariant="high-contrast"
      notifications={<Flashbar items={notifications} />}
      header={
        <Header variant="h1" info={<Link variant="info">Info</Link>}>
          Authentication
        </Header>
      }
    >
      <Container>
        <Form
          actions={
            <Button
              variant="primary"
              onClick={() => {
                authenticate(username, password);
              }}
            >
              Sign In
            </Button>
          }
        >
          <SpaceBetween size="m">
            <FormField label="Username">
              <Input value={username} onChange={(event) => setUsername(event.detail.value)} />
            </FormField>
            <FormField label="Password">
              <Input type="password" value={password} onChange={(event) => setPassword(event.detail.value)} />
            </FormField>
          </SpaceBetween>
        </Form>
      </Container>
    </ContentLayout>
  );
};

import Container from "@cloudscape-design/components/container";
import { Answer, QueryRecord } from "../../common/types/models";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Header from "@cloudscape-design/components/header";
import SegmentedControl from "@cloudscape-design/components/segmented-control";
import { FunctionComponent, useEffect, useState } from "react";
import Button from "@cloudscape-design/components/button";
import ExpandableSection from "@cloudscape-design/components/expandable-section";
import { convertCamelCaseToTitleCase } from "../../common/helpers";
import { AnswerView } from "./AnswerView";
import { FluxWidgetProps } from "../../common/standards";
import CollectionPreferences from "@cloudscape-design/components/collection-preferences";
import ButtonDropdown from "@cloudscape-design/components/button-dropdown";
import Box from "@cloudscape-design/components/box";
import TextFilter from "@cloudscape-design/components/text-filter";
import Cards from "@cloudscape-design/components/cards";
import Link from "@cloudscape-design/components/link";

export interface QueryRecordViewProps extends FluxWidgetProps {
  record: QueryRecord | null;
}

interface Item {
  field: string;
  answers: Answer[];
}

const renderValuesPreview = (answers: Answer[]) => {
  return answers
    .map((answer, _) => {
      if (answer.unit) return `${answer.value} (${answer.unit})`;
      return answer.value;
    })
    .join(", ");
};

export const QueryRecordView: FunctionComponent<QueryRecordViewProps> = ({ record }) => {
  const [mode, setMode] = useState("view");
  const [parsedRecord, setParsedRecord] = useState<Record<string, Answer[]>>({});
  const [saveLoading, setSaveLoading] = useState(false);
  const [items, setItems] = useState<Item[]>([]);

  const [selectedItems, setSelectedItems] = useState<Item[]>([]);

  useEffect(() => {
    if (record) {
      const val = JSON.parse(record.content);
      setParsedRecord(JSON.parse(record.content));
      setItems(
        Object.entries(val).map(([key, value]) => ({
          field: key,
          answers: value as Answer[],
        }))
      );
    }
  }, [record]);

  if (!record) return null;

  return (
    <Container>
      {/* <SpaceBetween size={"s"}>
        {Object.entries(parsedRecord).flatMap(([field, items]) => (
          <ExpandableSection headerText={`${convertCamelCaseToTitleCase(field)}`} key={`${field}`} headerDescription={field}>
            <SpaceBetween size={"s"}>
              {items.map((item, index) => (
                <AnswerView
                  inEdit={mode === "edit"}
                  index={`${index + 1}`}
                  key={`${field}-${index}`}
                  answer={item}
                  onChange={(answer) => {
                    // TODO: Implement answer change
                  }}
                />
              ))}
            </SpaceBetween>
          </ExpandableSection>
        ))}
      </SpaceBetween> */}
      <Cards
        onSelectionChange={({ detail }) => setSelectedItems(detail?.selectedItems ?? [])}
        selectedItems={selectedItems}
        cardDefinition={{
          header: (item) => <Link fontSize="heading-m">{convertCamelCaseToTitleCase(item.field)}</Link>,
          sections: [
            {
              id: "values",
              header: "Values",
              content: (item) => renderValuesPreview(item.answers),
            },
            // {
            //   id: "type",
            //   header: "Type",
            //   content: (item) => item.type,
            // },
            // {
            //   id: "size",
            //   header: "Size",
            //   content: (item) => item.size,
            // },
          ],
        }}
        cardsPerRow={[{ cards: 1 }, { minWidth: 500, cards: 2 }]}
        items={items}
        loadingText="Loading resources"
        selectionType="multi"
        trackBy="field"
        visibleSections={["values"]}
        empty={
          <Box margin={{ vertical: "xs" }} textAlign="center" color="inherit">
            <SpaceBetween size="m">
              <b>No resources</b>
              <Button>Create resource</Button>
            </SpaceBetween>
          </Box>
        }
        filter={<TextFilter filteringPlaceholder="Find resources" filteringText="" />}
        header={
          <Header
            counter={selectedItems?.length ? "(" + selectedItems?.length + "/10)" : "(10)"}
            description={`Source ID: ${record.sourceId} | Query ID: ${record.queryId}`}
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <ButtonDropdown
                  items={[
                    {
                      text: "Details",
                      id: "details",
                      disabled: false,
                    },
                    {
                      text: "Edit",
                      id: "edit",
                      disabled: false,
                    },
                  ]}
                >
                  Actions
                </ButtonDropdown>
                <Button>Delete</Button>
                <Button variant="primary">Add</Button>
              </SpaceBetween>
            }
          >
            Explore Query Record
          </Header>
        }
        // pagination={
        //   <Pagination currentPageIndex={1} pagesCount={2} />
        // }
        preferences={
          <CollectionPreferences
            title="Preferences"
            confirmLabel="Confirm"
            cancelLabel="Cancel"
            preferences={{
              pageSize: 6,
              visibleContent: ["description", "type", "size"],
            }}
            pageSizePreference={{
              title: "Page size",
              options: [
                { value: 6, label: "6 resources" },
                { value: 12, label: "12 resources" },
              ],
            }}
            visibleContentPreference={{
              title: "Select visible content",
              options: [
                {
                  label: "Main distribution properties",
                  options: [
                    {
                      id: "description",
                      label: "Description",
                    },
                    { id: "type", label: "Type" },
                    { id: "size", label: "Size" },
                  ],
                },
              ],
            }}
          />
        }
      />
    </Container>
  );
};
